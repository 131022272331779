import * as React from "react";
import "../styles/global.css";
import GatsbyImage from "gatsby-image";
import {Link} from "gatsby";


const Card = (props) => {
  return(
    <Link activeClassName="cursor-pointer" to={props.slug}>
      <div className="md:flex bg-white rounded-xl m-4 shadow hover-move-up overflow-hidden">
        <div className="md:block bg-blue-900 hidden">
          <GatsbyImage fixed={props.imageFixed}></GatsbyImage>
        </div>
        <div className="md:flex-auto md:p-8 m-2 p-3">
          <h1 className="text-2xl font-medium mb-2">{props.title}</h1>
          <h2 className="font-medium text-sm mb-2 uppercase tracking-wide">{props.date}</h2>
          <div className="mb-2 hidden lg:block">
            {props.excerpt}
          </div>
          <p>Author {props.author} * Read Time {props.readTime}</p>
        </div>
      </div>
    </Link>
  )
}

export default Card;