import * as React from "react";
import "../styles/global.css";
import Layout from "../layout/layout";
import {graphql, Link} from "gatsby";
import Card from "../components/card";

export const query = graphql`
  query ($category: String!) {
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "post"}, category: {eq: $category}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            category
            author
            date(formatString: "MMMM DD, YYYY")
            title
            thumbnail {
              childImageSharp {
                fixed(width: 270, height: 270) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          excerpt
          timeToRead
        }
      }
    }
  }
`

const Category = (props) => {

  const cards = props.data.allMarkdownRemark.edges.map( edge => {
    return(
      <Link to={`/blog${edge.node.fields.slug}`}>
        <Card title={edge.node.frontmatter.title}
              author={edge.node.frontmatter.author}
              date={edge.node.frontmatter.date}
              readTime={edge.node.timeToRead}
              excerpt={edge.node.excerpt}
              imageFixed={edge.node.frontmatter.thumbnail.childImageSharp.fixed}>
        </Card>
      </Link>
    )
  });

  return(
    <Layout>
      <div className="animate-fadeIn">
        {cards}
      </div>
    </Layout>
  )
}

export default Category;